import React from 'react'
import PropTypes from 'prop-types'
import DateInput from 'src/UIComponents/Date/DateInput'
import { RangeSelector } from 'src/UIComponents/Range'
import Filter from 'src/UIComponents/Filter/Filter'
import { SearchInput } from 'src/UIComponents/SearchInput'
import FilterContainer from 'src/UIComponents/Filter/FilterContainer'
import { SearchContainer } from './styles'
import FilterHeader from '../../UIComponents/Filter/FilterHeader'

const AnticipationFilter = props => {
  return (
    <FilterContainer borderRadius={'0'} padding='0px 0px 0px 20px'>
      <FilterHeader>Procurar por:</FilterHeader>
      <SearchContainer>
        <SearchInput placeholder='ID da antecipação' onSubmit={props.onSearchSubmit} onClear={props.onSearchClear} />
      </SearchContainer>
      <SearchContainer>
        <SearchInput placeholder='ID do estabelecimento' onSubmit={props.onSearchBySellerSubmit} onClear={props.onSearchBySellerClear} />
      </SearchContainer>
      <Filter defaultSearch={props.search} {...props} noSearchInput />
    </FilterContainer>
  )
}

AnticipationFilter.propTypes = {
  search: PropTypes.string,
  filters: PropTypes.array,
  onSearchSubmit: PropTypes.func,
  onSearchClear: PropTypes.func,
  onSearchBySellerSubmit: PropTypes.func,
  onSearchBySellerClear: PropTypes.func
}

AnticipationFilter.defaultProps = {
  filters: [
    {
      placeholder: 'Data',
      component: DateInput,
      accessor: 'date_range'
    },
    {
      placeholder: 'Status',
      accessor: 'status',
      isMultiSelect: false,
      options: [
        { label: 'Antecipada', value: 'succeeded' },
        { label: 'Aprovada', value: 'approved' },
        { label: 'Cancelada', value: 'canceled' },
        { label: 'Em análise', value: 'ready' },
        { label: 'Expirada', value: 'expired' },
        { label: 'Inválida', value: 'invalid' },
        { label: 'Processando simulação', value: 'requested' },
        { label: 'Rejeitada', value: 'rejected' },
        { label: 'Simulação disponível', value: 'simulated' }
      ]
    },
    {
      placeholder: 'Valor total líquido',
      component: RangeSelector,
      accessor: 'amount_range'
    }
  ]
}

export {
  AnticipationFilter
}
