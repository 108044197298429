import React, { Component, Fragment } from 'react'
import './styles/Authentication.scss'
import ForgotPassword from 'src/Components/Authentication/ForgotPassword'
import { Title } from 'src/Helpers/WhiteLabel'

export default class ForgotPassContainer extends Component {
  render () {
    return (
      <Fragment>
        <Title>Esqueci a senha</Title>
        <ForgotPassword {...this.props} />
      </Fragment>
    )
  }
}
