import React, { useState } from 'react'
import styled from 'styled-components'
import ReactSVG from 'react-svg'
import createToast from '../../Utils/createToast'
import Label from '../../UIComponents/Label/Label'
import { gray } from '../../Styles/settings/Constants'

export const LikeButtons = () => {
  const [show, setShow] = useState(true)

  const handleLikeButtons = (option) => {
    setShow(false)
    sessionStorage.setItem('setShow', false)
    createToast(
      'success',
      `Seu ${option} foi computado com sucesso!`
    )
  }

  return (
    <>
      {show && (
        <Container>
          <Label color={gray}>O que está achando do novo extrato?</Label>
          <ButtonsContainer>
            <Dislike
              src='/assets/icons/actions/dislike-hand.svg'
              onClick={() => { handleLikeButtons('dislike') }}
              data-tracking='Extrato Novo - Dislike'
            />
            <Like
              src='/assets/icons/actions/like-hand.svg'
              onClick={() => { handleLikeButtons('like') }}
              data-tracking='Extrato Novo - Like'
            />
            <SuperLike
              src='/assets/icons/actions/super-like-hand.svg'
              onClick={() => { handleLikeButtons('superlike') }}
              data-tracking='Extrato Novo - Super Like'
            />
          </ButtonsContainer>
        </Container>
      )}
    </>
  )
}

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
`

const Container = styled.div`
  margin-bottom: 2rem;
`

const Dislike = styled(ReactSVG)`
  border-radius: 5px;
  width: 61px;
  height: 29.878px;
  flex-shrink: 0;
`

const Like = styled(ReactSVG)`
  border-radius: 5px;
  width: 61px;
  height: 29.878px;
  flex-shrink: 0;

`

const SuperLike = styled(ReactSVG)`
  border-radius: 5px;
  width: 61px;
  height: 29.878px;
  flex-shrink: 0;
`
