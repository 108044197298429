import React from 'react'
import { BalanceFilter } from 'src/Containers/Balances/Filter'
import Can from 'src/Helpers/Permission/Can'
import Grid from 'src/UIComponents/Grid/Grid'
import { ReportButton } from 'src/Components/Report'
import styled from 'styled-components'
import { Tabs, TabList, Tab } from 'src/UIComponents/Tabs/Tabs'
import SectionedTable from '../../../Components/Table/SectionedTable'

export default function NewExtractComponent({
  balancesItems,
  filters,
  isBanking,
  tabIndex,
  tabs,
  search,
  handleSearchSubmit,
  handleFilterSubmit,
  handleClearSearchInput,
  balancesRequesting,
  handleClearAllFilters,
  columns,
  balancesTotal,
  balancesTotalPages,
  balancesPageSize,
  balancesError,
  emptyStateMsg,
  emptyStateDescription,
  emptyStateIconPath,
  currentPage,
  handleResultsPerPageChange,
  handlePageChange,
  handleSortChange,
  handleOnSelectTab,
  onItemClick,
  amountType,
  handleChangeAmountType,
  handleIdTransaction,
  clearIdTransaction
}) {
  return (
    <>
      <Grid.Row>
        <Grid.Col cols={16}>
          {balancesItems && (
            <ReportButtonContainer>
              <Can do='create' on='ExportTransaction'>
                <ReportButton type='entries' filters={filters} results={balancesItems.length} />
              </Can>
            </ReportButtonContainer>
          )}
        </Grid.Col>
      </Grid.Row>
      {isBanking && (
        <Grid.Row auto marginBottom={'0'}>
          <Tabs marginTop='-3rem' selectedIndex={tabIndex} onSelect={(tabIndex) => handleOnSelectTab(tabIndex)}>
            <TabList>
              {tabs.map((tab) => (
                <Tab key={tab.title} newExtract>
                  {tab.title}
                </Tab>
              ))}
            </TabList>
          </Tabs>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Col cols={16}>
          <BalanceFilter
            search={search}
            values={filters}
            searchPlaceholder={'Buscar pelo CPF/CNPJ ou Nome'}
            onSearchSubmit={handleSearchSubmit}
            onSearchClear={handleClearSearchInput}
            onFiltersApply={handleFilterSubmit}
            onFiltersClear={handleClearAllFilters}
            onAmountTypeChange={handleChangeAmountType}
            typeChange={amountType}
            handleIdTransaction={handleIdTransaction}
            clearIdTransaction={clearIdTransaction}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row auto id='table-history'>
        <Can I='read' a='SellerDetail' passThrough>
          {(canReadDetail) => (
            <SectionedTable
              loading={balancesRequesting}
              tableColumns={columns}
              tableData={balancesItems}
              totalItems={balancesTotal}
              totalPages={balancesTotalPages}
              pageSize={balancesPageSize}
              error={balancesError}
              emptyState={{
                message: emptyStateMsg,
                description: emptyStateDescription,
                icon: emptyStateIconPath
              }}
              iconComponent={canReadDetail ? undefined : false}
              currentPage={currentPage}
              manual
              showPageSizeOptions
              onResultsPerPageChange={handleResultsPerPageChange}
              onPageChange={handlePageChange}
              onSortChange={handleSortChange}
              onItemClick={onItemClick}
              isBanking={tabIndex === 1}
              defaultSortBy={[
                {
                  id: 'nsu',
                  desc: true
                }
              ]}
              data-tracking='Balances'
              typeChange={amountType}
            />
          )}
        </Can>
      </Grid.Row>
    </>
  )
}

const ReportButtonContainer = styled.div`
  margin-top: 10px;
`
