import React, { Fragment, Component } from 'react'
import { Title } from 'src/Helpers/WhiteLabel'

import RecoverPassword from 'src/Components/Authentication/RecoverPassword'

export default class RecoverPasswordContainer extends Component {
  render () {
    return (
      <Fragment>
        <Title>Alterar Senha</Title>
        <RecoverPassword />
      </Fragment>
    )
  }
}
