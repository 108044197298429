import React from 'react'
import DateInput from 'src/UIComponents/Date/DateInput'
import Filter from 'src/UIComponents/Filter/Filter'
import PropTypes from 'prop-types'
import { RangeSelectorNegative } from '../../UIComponents/Range/RangeSelectorNegative'

const BalanceFilter = (props) => <Filter noSearchInput {...props} />

BalanceFilter.propTypes = {
  search: PropTypes.string,
  filters: PropTypes.array
}

BalanceFilter.defaultProps = {
  filters: [
    {
      placeholder: 'Data de criação',
      component: DateInput,
      accessor: 'date_range'
    },
    {
      placeholder: 'Tipo',
      accessor: 'type',
      isMultiSelect: true,
      transformer: {
        format: (value) => {
          return typeof value === 'string' ? (value.length > 0 ? value.split(',') : undefined) : value
        },
        parse: (value) => {
          if (value && Array.isArray([...value])) {
            const selectedStatuses = [...value]
            const values = selectedStatuses.map(({ value }) => value)
            return values.join(',')
          }
          return value
        }
      },
      options: [
        { label: 'Ajuste', value: 'adjustment' },
        { label: 'Bloqueio', value: 'block' },
        { label: 'Depósitos', value: 'deposit' },
        { label: 'Depósitos estornados', value: 'deposit_refunded' },
        { label: 'Desbloqueio', value: 'block_refunded' },
        { label: 'Transferências', value: 'transfer' },
        { label: 'Transferências estornadas', value: 'transfer_refunded' },
        { label: 'Vendas', value: 'charge' },
        { label: 'Vendas estornadas', value: 'charge_refunded' }
      ]
    },
    {
      placeholder: 'Valor de lançamento',
      component: RangeSelectorNegative,
      accessor: 'net_amount_range'
    }
  ]
}

export { BalanceFilter }
