/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react'
import { getFutureTransfers, resetFutureTransfers } from 'src/Actions/futureTransfers'
import { parseDate, toMoney } from 'src/Utils/Utils'
import { useDispatch, useSelector } from 'react-redux'

import CardBrandDisplay from 'src/UIComponents/CardBrandDisplay/CardBrandDisplay'
import { FutureTransfersFilter } from 'src/Containers/FutureTransfers/Filter'
import Grid from 'src/UIComponents/Grid/Grid'
import LabelCopyToClipboard from 'src/UIComponents/LabelCopyToClipboard/LabelCopyToClipboard'
import PropTypes from 'prop-types'
import Table from 'src/Components/Table/NewTable'
import Title from 'src/UIComponents/Title/Title'
import { clamp } from 'lodash'
import { darkerGray } from '../../Styles/settings/Constants'
import moment from 'moment'
import { useQuery } from 'src/Utils/Hooks'

const FutureTransfersTable = ({ columns, onItemClick }) => {
  const dispatch = useDispatch()

  const {
    loading,
    futureTransfersErrors,
    futureTransfersList,
    futureTransfersTotalPages,
    futureTransfersTotalItems,
    futureTransfersPageSize
  } = useSelector(({ futureTransfers }) => {
    return {
      loading: futureTransfers && futureTransfers.futureTransfersListRequesting,
      futureTransfersList: futureTransfers && futureTransfers.futureTransfersList,
      futureTransfersTotalPages: futureTransfers && futureTransfers.futureTransfersListTotalPages,
      futureTransfersTotalItems: futureTransfers && futureTransfers.futureTransfersListTotalItems,
      futureTransfersPageSize: futureTransfers && futureTransfers.futureTransfersPageSize,
      futureTransfersErrors: futureTransfers && futureTransfers.futureTransfersListError
    }
  })

  const query = useQuery()
  const [filters, setFilters] = useState(query)
  const [search, setSearch] = useState()
  const [currentPage, setCurrentPage] = useState(0)
  const [resultsPerPage, setResultsPerPage] = useState(futureTransfersPageSize)
  const [sort] = useState('created_at:desc')
  const [futureTransfersItems, setFutureTransfersItems] = useState(null)

  useEffect(() => {
    if (loading) return
    dispatch(resetFutureTransfers())
    dispatch(getFutureTransfers({
      ...filters,
      limit: resultsPerPage,
      offset: currentPage * resultsPerPage,
      sort
    }))
  }, [filters, resultsPerPage, currentPage, search, sort])

  const handleFilterSubmit = (newFilters = {}) => {
    handleClearSearchInput()
    setFilters(newFilters)
  }

  const handleClearAllFilters = () => {
    setCurrentPage(0)
    setFilters({})
  }

  const handleClearSearchInput = () => {
    setCurrentPage(0)
    setSearch()
  }

  const handleResultsPerPageChange = (value) => {
    setCurrentPage(0)
    setResultsPerPage(value)
  }

  const handlePageChange = page => {
    const newPage = clamp(page, 0, futureTransfersTotalPages)
    setCurrentPage(newPage)
  }

  useEffect(() => {
    if (futureTransfersList) {
      const dateKeys = Object.keys(futureTransfersList)
      const futureTransfersGroup = []
      dateKeys.forEach(key => {
        const items = futureTransfersList[key].items
        const totalAmount = futureTransfersList[key].total_amount
        futureTransfersGroup.push({
          type: 'subHeader',
          title: <>Pagamento previsto <strong>{moment(key).format('DD/MM/YYYY')}</strong></>,
          totalAmount: <strong>{parseFloat(totalAmount).toMoney()}</strong>
        })

        futureTransfersGroup.push(...items)
      })
      setFutureTransfersItems(futureTransfersGroup)
    }
  }, [futureTransfersList])

  return (
    <>
      <Grid.Row>
        <Grid.Col cols={10}>
          <Title bar small>
            <strong>Lista</strong> de pagamentos previstos
          </Title>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col cols={16}>
          <FutureTransfersFilter
            search={search}
            values={filters}
            onSearchClear={handleClearSearchInput}
            onFiltersApply={handleFilterSubmit}
            onFiltersClear={handleClearAllFilters}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row auto id='table-history'>
        <Table
          loading={loading}
          tableColumns={columns}
          tableData={futureTransfersItems}
          totalItems={futureTransfersTotalItems}
          totalPages={futureTransfersTotalPages}
          pageSize={futureTransfersPageSize}
          error={futureTransfersErrors}
          emptyState={{
            message: search ? 'Não encontramos esta venda' : ' Nenhuma venda encontrada de acordo\n com os filtros aplicados',
            description: search && 'Verifique se o ID que você buscou está correto',
            icon: search ? '/assets/icons/actions/search.svg' : '/assets/icons/others/sales.svg'
          }}
          manual
          currentPage={currentPage}
          showPageSizeOptions
          chessBackground={false}
          onResultsPerPageChange={handleResultsPerPageChange}
          onPageChange={handlePageChange}
          // onSortChange={handleSortChange}
          onItemClick={onItemClick}
        />
      </Grid.Row>
    </>
  )
}

FutureTransfersTable.defaultProps = {
  filters: {},
  columns: [
    {
      Header: 'Data',
      accessor: 'created_at',
      Cell: ({ cell }) => {
        return (<div>
          {parseDate(cell.value, 'DD/MM/YYYY')}
          <br />
          {parseDate(cell.value, 'HH:mm:ss')}
        </div>)
      },
      width: '10%',
      sortable: false
    },
    {
      Header: 'ID da transação',
      Cell: ({ row }) => {
        const { original } = row
        return (
          <LabelCopyToClipboard
            color={darkerGray}
            text={original.transaction_hash_id}
          >
            {original.transaction_hash_id}
          </LabelCopyToClipboard>
        )
      },
      width: '25%'
    },
    {
      Header: 'Tipo de pagamento',
      Cell: ({ row }) => (
        <div>
          <CardBrandDisplay
            src={row.original.payment_method ? row.original.payment_method.card_brand : undefined}
            paymentType={row.original.payment_type}
            installmentPlan={row.original.installment_plan}
          />
        </div>
      ),
      width: '25%'
    },
    {
      Header: 'Valor bruto',
      accessor: 'amount',
      Cell: ({ cell }) => toMoney(cell.value / 100)
    },
    {
      Header: 'Taxa',
      accessor: 'fees',
      Cell: ({ cell }) => toMoney(cell.value / 100)
    },
    {
      Header: 'Valor líquido',
      accessor: 'net',
      Cell: ({ cell }) => toMoney(cell.value / 100)
    }
  ],
  onItemClick: () => { /* noop */ }
}

FutureTransfersTable.propTypes = {
  filters: PropTypes.object,
  columns: PropTypes.array,
  onItemClick: PropTypes.func
}

export {
  FutureTransfersTable as Table
}
