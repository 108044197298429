import DateInput from 'src/UIComponents/Date/DateInput'
import Filter from 'src/UIComponents/Filter/Filter'
import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
const FutureTransfersFilter = props => <Filter noSearchInput {...props} />

FutureTransfersFilter.propTypes = {
  filters: PropTypes.array
}

FutureTransfersFilter.defaultProps = {
  filters: [
    {
      placeholder: 'Data',
      component: DateInput,
      accessor: 'date_range',
      gteLimit: moment(new Date()).startOf('day'),
      options: [
        {
          label: 'Hoje',
          value: {
            gte: moment().startOf('day').toDate(),
            lte: moment().endOf('day').toDate()
          }
        },
        {
          label: 'Amanhã',
          value: {
            gte: moment().add(1, 'day').startOf('day').toDate(),
            lte: moment().add(1, 'day').endOf('day').toDate()
          }
        },
        {
          label: 'Essa semana',
          value: {
            gte: moment().startOf('day').toDate(),
            lte: moment().endOf('week').toDate()
          }
        },
        {
          label: 'Próxima semana',
          value: {
            gte: moment().add(1, 'week').startOf('week').toDate(),
            lte: moment().add(1, 'week').endOf('week').toDate()
          }
        },
        {
          label: 'Esse mês',
          value: {
            gte: moment().startOf('day').toDate(),
            lte: moment().endOf('month').toDate()
          }
        },
        {
          label: 'Próximo mês',
          value: {
            gte: moment().add(1, 'month').startOf('month').toDate(),
            lte: moment().add(1, 'month').endOf('month').toDate()
          }
        },
        {
          label: 'Até um ano',
          value: {
            gte: moment().startOf('day').toDate(),
            lte: moment().add(1, 'year').endOf('day').toDate()
          },
          divisor: true
        }
      ]
    }
  ]
}

export {
  FutureTransfersFilter
}
