import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { Title as Helmet } from 'src/Helpers/WhiteLabel'

import MainTemplate, { Container, Column } from 'src/UIComponents/Template'
import Grid from 'src/UIComponents/Grid/Grid'
import Title from 'src/UIComponents/Title/Title'
import InfoCard from 'src/UIComponents/InfoCard/InfoCard'

import { Table } from 'src/Containers/Balances/Table'
import { useSelector } from 'react-redux'
import { normalText } from '../../Styles/settings/Constants'
import Label from '../../UIComponents/Label/Label'

const Balances = () => {
  const history = useHistory()
  const [oldComponentRender, setOldComponentRender] = useState(false)

  const { seller: sellerContext } = useSelector(({ context }) => context)

  const handleItemClick = (item) => {
    if (item && item.transaction) {
      history.push(
        `/estabelecimento/${sellerContext.id}/extrato/venda/${item.transaction.id}`
      )
    } else if (item && item.transfer) {
      history.push(
        `/estabelecimento/${sellerContext.id}/extrato/transferencia/${item.transfer.id}?transferType=${item.entry_type}`
      )
    }
  }

  return (
    <MainTemplate>
      <Helmet>Extrato</Helmet>
      <TitleContainer>
        <Title bar big data-test={`title-page-extrato`}>
          <strong>Extrato</strong>
        </Title>
        <SwitchContainer>
          <Label fontSize={normalText}>
            <strong>Nova</strong> visualização
          </Label>
          <Toggle
            className='toggle'
            toggle={oldComponentRender}
            onClick={() => {
              setOldComponentRender((prev) => !prev)
            }}
          >
            <div
              className={`toggle__background ${!oldComponentRender && 'toggle__background__animate'}`}
            />
            <div
              className={`toggle__ball ${!oldComponentRender && 'toggle__ball__animate'}`}
            />
          </Toggle>
        </SwitchContainer>
      </TitleContainer>

      <Container>
        <Column>
          <InfoCard title='Importante!'>
            {`Os valores apresentados não consideram os impactos de contratos (circular nº 3.952, em vigor a partir do dia 07 de junho de 2021), por isso, poderá haver divergência entre os valores previstos e transferidos.
              Em caso de dúvida, entre em contato com o suporte.`}
          </InfoCard>
          <Grid noPadding>
            <Table
              onItemClick={handleItemClick}
              oldComponentRender={oldComponentRender}
            />
          </Grid>
        </Column>
      </Container>
    </MainTemplate>
  )
}

export default Balances

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7.5rem;
`

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  align-items: end;

  > span {
    margin-bottom: -10px;
  }
`

const Toggle = styled.div`
  margin-top: 1.5rem;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;

  &:hover {
    cursor: pointer;
  }

  .toggle__background {
    width: 100%;
    height: 100%;
    border-radius: 1em;
    background-color: rgba(204, 204, 204, 0.4);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .toggle__ball {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 18px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.color} !important;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .toggle__ball__animate {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  .toggle__background__animate {
    background-color: ${(props) => props.theme.color} !important;
    opacity: 0.4;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
`
