import React, { useEffect } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'

import Can from 'src/Helpers/Permission/Can'
import { Title as Helmet } from 'src/Helpers/WhiteLabel'
import Title from 'src/UIComponents/Title/Title'

import MainTemplate, { Container, Column } from 'src/UIComponents/Template'
import Grid from 'src/UIComponents/Grid/Grid'
import { Tabs, TabList, Tab, TabPanel } from 'src/UIComponents/Tabs/Tabs'
import InnerHeader from '../../UIComponents/Template/InnerHeader'
import { Table } from './Table'
import { CreateAnticipation } from '../../Components/AnticipationComponent/create/index'
import ReportButtonNotifier from '../../UIComponents/ReportButtonNotifier/ReportButtonNotifier'

const Anticipation = () => {
  const TABS = [
    {
      name: 'Simulação',
      url: '/antecipacoes/simulacao'
    },
    {
      name: 'Antecipações',
      url: '/antecipacoes'
    }
  ]

  const history = useHistory()
  const routeMatch = useRouteMatch({
    path: ['*/antecipacoes/simulacao', '*/antecipacoes/:id', '*/antecipacoes'],
    exact: true
  })

  const [tabIndex, setTabIndex] = React.useState(0)

  useEffect(() => {
    const url = routeMatch?.url
    const tabIndex = TABS.findIndex((tab) => url?.includes(tab.url))

    if (tabIndex < 0) {
      history.push(`/antecipacoes`)
      setTabIndex(1)
    } else {
      setTabIndex(tabIndex)
    }
  }, [routeMatch])

  const handleTabChange = (index) => {
    setTabIndex(index)
    history.push(TABS[index].url)
  }

  return (
    <MainTemplate>
      <Helmet>Antecipação</Helmet>
      <Can do='read' on='Anticipation'>
        <Container>
          <Column>
            <InnerHeader marginTop='2.5rem'>
              <Title bar big>
                <strong>Antecipação de recebíveis</strong>
              </Title>
            </InnerHeader>
            <Grid noPadding>
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => handleTabChange(index)}
              >
                <Grid.Row noMargin auto>
                  <TabList>
                    {TABS.map((tab) => (
                      <Tab variant='filled' key={tab.name}>
                        {tab.name}
                      </Tab>
                    ))}
                  </TabList>
                  <ReportButtonNotifier />
                </Grid.Row>
                <TabPanel>
                  <CreateAnticipation setTabIndex={setTabIndex} />
                </TabPanel>
                <TabPanel>
                  <Table setTabIndex={setTabIndex} />
                </TabPanel>
              </Tabs>
            </Grid>
          </Column>
        </Container>
      </Can>
    </MainTemplate>
  )
}

export default Anticipation
