import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import MainTemplate, { Container, Column, InnerHeader } from 'src/UIComponents/Template'
import AccountComponent from 'src/Components/AccountComponent/AccountComponent'
import CardConfigComponent from 'src/Components/CardConfigComponent/CardConfigComponent'
import BankAccountsComponent from 'src/Components/BankAccountsComponent/BankAccountsComponent'
import UsersComponent from 'src/Components/UsersComponent/UsersComponent'
import WebhooksComponent from 'src/Components/WebhooksComponent/WebhooksComponent'
import PlansComponent from 'src/Components/PlansComponent/PlansComponent'
import CustomizeComponent from 'src/Components/CustomizeComponent/CustomizeComponent'
import FeatureFlagsComponent from 'src/Components/FeatureFlagsComponent/FeatureFlagsComponent'
import { Title as Helmet } from 'src/Helpers/WhiteLabel'

const MarketplaceConfiguration = ({ history }) => {
  return (
    <MainTemplate>
      <InnerHeader configMargin />
      <Container>
        <Column>
          <CardConfigComponent />
        </Column>
        <Column>
          <Switch location={location}>
            <Route
              path='*/cadastro'
              render={() => <><Helmet>Dados Cadastrais</Helmet><AccountComponent /></>}
            />
            <Route
              path='*/meios-recebimento'
              render={() => <><Helmet>Meios de Recebimento</Helmet><BankAccountsComponent data-tracking={`Configuracoes > Meios de recebimento`} /></>}
            />
            <Route
              path='*/usuarios'
              render={() => <><Helmet>Usuários</Helmet><UsersComponent /></>}
            />
            <Route
              path='*/webhooks'
              render={() => <><Helmet>Webhooks</Helmet><WebhooksComponent /></>}
            />
            <Route
              path='*/planos'
              render={() => <><Helmet>Planos</Helmet><PlansComponent history={history} /></>}
            />
            <Route
              path='*/personalizacao'
              render={() => <><Helmet>Personalização</Helmet><CustomizeComponent history={history} /></>}
            />
            <Route
              path='*/recursos-minha-conta'
              render={() => <><Helmet>Recursos do Minha Conta</Helmet><FeatureFlagsComponent history={history} /></>}
            />
            <Redirect to='/configuracoes/cadastro' />
          </Switch>
        </Column>
      </Container>
    </MainTemplate>
  )
}

MarketplaceConfiguration.propTypes = {
  history: PropTypes.object
}

export default MarketplaceConfiguration
