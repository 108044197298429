import React, { Fragment, Component } from 'react'
import { Title } from 'src/Helpers/WhiteLabel'

import './styles/Authentication.scss'
import AccessForm from 'src/Components/AuthForm/AccessForm'

export default class LoginContainer extends Component {
  render () {
    return (
      <Fragment>
        <Title>Login</Title>
        <AccessForm />
      </Fragment>

    )
  }
}
