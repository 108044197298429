/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react'
import Can from 'src/Helpers/Permission/Can'
import PropTypes from 'prop-types'
import Table from 'src/Components/Table/NewTable'
import {
  getAnticipations,
  getAnticipationsBySeller
} from '../../Actions/anticipations'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { deepCopy, toMoney } from '../../Utils/Utils'
import { useQuery } from 'src/Utils/Hooks'
import { clamp } from 'lodash'
import moment from 'moment'
import { AnticipationFilter } from './Filter'
import { getStatusLabel } from '../../Constants/AnticipationStatus'
import {
  darkerGray,
  smallText,
  darkGray,
  normalText
} from 'src/Styles/settings/Constants'
import styled from 'styled-components'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import LabelCopyToClipboard from 'src/UIComponents/LabelCopyToClipboard/LabelCopyToClipboard'
import { SummaryAnticipation } from '../../Components/AnticipationComponent/summary'
import ButtonDefault from '../../UIComponents/Button/ButtonDefault'

const AnticipationTable = ({ columns }) => {
  const [anticipations, setAnticipations] = useState([])
  const [loading, setLoading] = useState(false)
  const query = useQuery()
  const [filters, setFilters] = useState(query)
  const [search, setSearch] = useState()
  const [searchBySeller, setSearchBySeller] = useState()
  const [currentPage, setCurrentPage] = useState(
    filters ? (filters.offset || 0) / (filters.limit || 100) : 0
  )
  const [resultsPerPage, setResultsPerPage] = useState(filters.limit || 100)
  const [error, setError] = useState(null)
  const history = useHistory()
  const matchHabilitarPos = useRouteMatch('*/habilitar-pos')

  useEffect(() => {
    const asyncCall = async () => {
      setLoading(true)
      setError(null)

      const newFilters = deepCopy(filters)

      if (newFilters?.amount_range?.gte != null) {
        newFilters.amount_range.gte = newFilters.amount_range.gte / 100
      }

      if (newFilters?.amount_range?.lte != null) {
        newFilters.amount_range.lte = newFilters.amount_range.lte / 100
      }

      let request = null
      if (search) {
        request = getAnticipations({
          ...newFilters,
          limit: resultsPerPage,
          offset: currentPage * resultsPerPage,
          id: search
        })
      } else if (searchBySeller) {
        request = getAnticipationsBySeller(searchBySeller, {
          ...newFilters,
          limit: resultsPerPage,
          offset: currentPage * resultsPerPage
        })
      } else {
        request = getAnticipations({
          ...newFilters,
          limit: resultsPerPage,
          offset: currentPage * resultsPerPage
        })
      }

      const response = await request

      if (response.error) {
        setError(response)
      } else {
        setAnticipations(response)
      }

      setLoading(false)
    }

    asyncCall()
  }, [filters, resultsPerPage, currentPage, search, searchBySeller])

  const handleFilterSubmit = (newFilters = {}) => {
    handleClearSearchInput()
    setFilters(newFilters)
  }

  const handleClearAllFilters = () => {
    setCurrentPage(0)
    setFilters({})
  }

  const handleSearchBySellerSubmit = (value) => {
    setSearchBySeller(value.trim().replace(/[.,\-,/]/g, ''))
  }

  const handleSearchSubmit = (value) => {
    setSearch(value.trim().replace(/[.,\-,/]/g, ''))
  }

  const handleClearSearchInput = () => {
    setCurrentPage(0)
    setSearch()
  }

  const handleClearSearchBySellerInput = () => {
    setCurrentPage(0)
    setSearchBySeller()
  }

  const handleResultsPerPageChange = (value) => {
    setCurrentPage(0)
    setResultsPerPage(value)
  }

  const handlePageChange = (page) => {
    const newPage = clamp(page, 0, anticipations?.total_pages)
    setCurrentPage(newPage)
  }

  const handleItemClick = (item) => {
    history.push(`/antecipacoes/${item.id}`)
  }

  const emptyStateMsg = search
    ? 'Não encontramos esta antecipação'
    : `Nenhuma antecipação encontrada de\n acordo com os filtros aplicados`
  const emptyStateDescription =
    search && 'Verifique se o nome ou número digitados estão corretos'
  const emptyStateIconPath = search
    ? '/assets/icons/actions/search.svg'
    : '/assets/icons/others/enterprise.svg'

  return (
    <>
      <Grid.Row>
        <Grid.Col cols={16}>
          <AnticipationFilter
            search={search}
            values={filters}
            searchPlaceholder={'Buscar pelo ID da antecipação'}
            onSearchSubmit={handleSearchSubmit}
            onSearchClear={handleClearSearchInput}
            onSearchBySellerSubmit={handleSearchBySellerSubmit}
            onSearchBySellerClear={handleClearSearchBySellerInput}
            onFiltersApply={handleFilterSubmit}
            onFiltersClear={handleClearAllFilters}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row auto id='table-history'>
        <Can I='read' a='SellerDetail' passThrough>
          {(canReadDetail) => (
            <Table
              loading={loading}
              tableColumns={columns}
              tableData={anticipations?.items}
              totalItems={anticipations?.total}
              totalPages={anticipations?.total_pages}
              pageSize={anticipations?.limit}
              error={error}
              emptyState={{
                message: emptyStateMsg,
                description: emptyStateDescription,
                icon: emptyStateIconPath
              }}
              onItemClick={handleItemClick}
              iconComponent={canReadDetail ? undefined : false}
              currentPage={currentPage}
              manual
              showPageSizeOptions
              onResultsPerPageChange={handleResultsPerPageChange}
              onPageChange={handlePageChange}
              defaultSortBy={[
                {
                  id: 'created_at',
                  desc: true
                }
              ]}
              data-tracking='Sellers'
            />
          )}
        </Can>
      </Grid.Row>
      {!matchHabilitarPos && <SummaryAnticipation />}
    </>
  )
}

AnticipationTable.defaultProps = {
  columns: [
    {
      Header: 'Data da Criação',
      accessor: 'created_at',
      width: '10%',
      Cell: ({ cell }) => {
        if (!cell.value) {
          return
        }
        return (
          <Label fontSize={normalText} color={darkGray}>
            {moment(cell.value).format('DD/MM/YY')} <br />{' '}
            <Label fontSize={smallText} color={darkGray}>
              {moment(cell.value).subtract(3, 'hours').format('HH:mm:ss')}
            </Label>{' '}
          </Label>
        )
      },
      sortable: true,
      maxWidth: 200
    },
    {
      Header: 'ID da antecipação',
      accessor: 'id',
      width: '25%',
      sortable: false,
      Cell: ({ cell }) => {
        const { value } = cell
        return (
          <LabelCopyToClipboard fontSize={smallText} text={value}>
            {value}
          </LabelCopyToClipboard>
        )
      },
      maxWidth: 200
    },
    {
      Header: 'ID do estabelecimento',
      width: '25%',
      accessor: 'customer',
      Cell: ({ cell }) => {
        const { value } = cell
        return (
          <LabelCopyToClipboard
            color={darkerGray}
            fontSize={smallText}
            text={value}
          >
            {value}
          </LabelCopyToClipboard>
        )
      },
      sortable: false
    },
    {
      Header: 'Valor total líquido',
      width: '10%',
      accessor: 'prepayment_net_amount',
      Cell: ({ cell }) => <strong>{toMoney(cell.value / 100)}</strong>,
      sortable: false
    },
    {
      Header: 'Valor total bruto',
      width: '10%',
      accessor: 'prepayment_gross_amount',
      Cell: ({ cell }) => <strong>{toMoney(cell.value / 100)}</strong>,
      sortable: false
    },
    {
      Header: 'Status',
      width: '30%',
      accessor: 'status',
      Cell: ({ cell }) => (
        <StatusContainer>
          <Label color={getStatusLabel(cell.value).color} bold uppercase>
            {getStatusLabel(cell.value).label}
          </Label>
          {cell.value === 'simulated' ? (
            <ButtonDefault widthAuto>Ver Simulação</ButtonDefault>
          ) : (
            cell.value === 'requested' && (
              <ButtonDefault widthAuto disabled>
                Ver Simulação
              </ButtonDefault>
            )
          )}
        </StatusContainer>
      ),
      sortable: false
    }
  ]
}

AnticipationTable.propTypes = {
  columns: PropTypes.array
}

const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export { AnticipationTable as Table }
