/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react'
import { darkGray, normalText, darkerGray } from 'src/Styles/settings/Constants'
import { getBalances, resetBalances } from 'src/Actions/balances'
import { useDispatch, useSelector } from 'react-redux'
import Label from 'src/UIComponents/Label/Label'
import PropTypes from 'prop-types'
import { clamp } from 'lodash'
import { getStatusLabel } from 'src/Constants/StatusLabels'
import { useQuery } from 'src/Utils/Hooks'
import OldExtractComponent from './subComponents/OldExtractComponent'
import NewExtractComponent from './subComponents/NewExtractComponent'
import styled from 'styled-components'
import { LikeButtons } from './LikeButtons'
import moment from 'moment'
import LabelCopyToClipboard from 'src/UIComponents/LabelCopyToClipboard/LabelCopyToClipboard'

const BalanceTable = ({
  columns,
  columnsOld,
  onItemClick,
  oldComponentRender
}) => {
  const dispatch = useDispatch()

  const {
    balancesItems,
    balancesTotalPages,
    balancesTotal,
    balancesPageSize,
    balancesError,
    balancesRequesting,
    isBanking
  } = useSelector(({ context, sellers, balances }) => {
    return {
      sellers,
      balancesItems: balances.balancesItems,
      balancesTotalPages: balances.balancesTotalPages,
      balancesTotal: balances.balancesTotal,
      balancesPageSize: balances.balancesPageSize,
      balancesRequesting: balances.balancesRequesting,
      balancesError: balances.balancesError,
      isBanking: context.seller?.payout_option === 'BANKING'
    }
  })

  const tabs = [
    {
      title: 'Payments',
      segment_type: 'payments'
    },
    {
      title: 'Banking',
      segment_type: 'banking'
    }
  ]

  const query = useQuery()
  const [filters, setFilters] = useState({
    segment_type: 'payments',
    ...query
  })
  const [search, setSearch] = useState()
  const [amountType, setAmountType] = useState('all')
  const [currentPage, setCurrentPage] = useState(
    filters ? (filters.offset || 0) / balancesPageSize : 0
  )
  const [resultsPerPage, setResultsPerPage] = useState(balancesPageSize)
  const [sort, setSort] = useState('nsu:desc')

  const tabIndexFromQuery = tabs
    .map((tab) => tab.segment_type)
    .indexOf(query?.segment_type)
  const [tabIndex, setTabIndex] = useState(
    tabIndexFromQuery >= 0 ? tabIndexFromQuery : 0
  )

  if (tabIndex === 1) {
    columns = columns.filter((item, index) => index !== 3)
  }

  useEffect(() => {
    dispatch(
      getBalances(
        tabs[tabIndex]?.segment_type,
        {},
        oldComponentRender ? null : 1
      )
    )
    return () => {
      dispatch(resetBalances())
    }
  }, [])

  useEffect(() => {
    if (balancesRequesting) return

    let key = 'name'
    if (parseInt(search)) {
      if (search.length === 11) {
        key = 'taxpayer_id'
      } else if (search.length === 14) {
        key = 'ein'
      }
    }

    const newRender = oldComponentRender === true ? null : 1

    dispatch(
      getBalances(
        tabs[tabIndex]?.segment_type,
        {
          ...filters,
          limit: resultsPerPage,
          offset: currentPage * resultsPerPage,
          [key]: search,
          sort
        },
        newRender
      )
    )
  }, [
    filters,
    resultsPerPage,
    currentPage,
    search,
    sort,
    tabIndex,
    oldComponentRender
  ])

  const handleFilterSubmit = (newFilters = {}) => {
    handleClearSearchInput()
    setFilters(newFilters)
  }

  const handleClearAllFilters = () => {
    setCurrentPage(0)
    setFilters({})
  }

  const handleSearchSubmit = (value) => {
    handleClearAllFilters()
    setSearch(value.trim().replace(/[.,\-,/]/g, ''))
  }

  const handleClearSearchInput = () => {
    setCurrentPage(0)
    setSearch()
  }

  const handleResultsPerPageChange = (value) => {
    setCurrentPage(0)
    setResultsPerPage(value)
  }

  const handlePageChange = (page) => {
    const newPage = clamp(page, 0, balancesTotalPages)
    setCurrentPage(newPage)
  }

  const handleSortChange = (sorted) => {
    setCurrentPage(0)
    let sort = 'nsu:desc'

    if (sorted.length > 0 && !sorted[0].desc) {
      sort = 'nsu:asc'
    }
    setSort(sort)
  }

  const handleOnSelectTab = (index) => {
    setTabIndex(index)
    setFilters({
      ...filters,
      segment_type: tabs[index]?.segment_type
    })
  }

  const handleChangeAmountType = (value) => {
    let newFilters = { ...filters }
    delete newFilters?.['amount_range[lte]']
    delete newFilters?.['amount_range[gte]']
    delete newFilters?.['blocked_amount_range[gte]']

    if (value !== 'all') {
      if (value === 'positive') {
        newFilters['amount_range[gte]'] = 0
      }
      if (value === 'negative') {
        newFilters['amount_range[lte]'] = 0
      }
      if (value === 'agendadas') {
        newFilters['blocked_amount_range[gte]'] = 1
      }
    }

    setAmountType(value)
    setFilters(newFilters)
    setCurrentPage(0)
  }

  const handleIdTransaction = (id) => {
    setFilters({
      ...filters,
      search_id: id
    })
  }

  const clearIdTransaction = () => {
    setFilters({
      ...filters,
      search_id: null
    })
  }

  const emptyStateMsg = search
    ? 'Não encontramos esta informação no extrato'
    : `Nenhuma informação de extrato encontrada de\n acordo com os filtros aplicados`
  const emptyStateDescription =
    search && 'Verifique se o nome ou número digitados estão corretos'
  const emptyStateIconPath = search
    ? '/assets/icons/actions/search.svg'
    : '/assets/icons/others/enterprise.svg'

  return (
    <>
      <ChangeViewWrapper isBanking={isBanking}>
        {!oldComponentRender && !sessionStorage.getItem('setShow') ? (
          <LikeButtons />
        ) : null}
      </ChangeViewWrapper>
      <>
        {oldComponentRender ? (
          <OldExtractComponent
            balancesItems={balancesItems}
            filters={filters}
            isBanking={isBanking}
            tabIndex={tabIndex}
            tabs={tabs}
            search={search}
            handleSearchSubmit={handleSearchSubmit}
            handleFilterSubmit={handleFilterSubmit}
            handleClearAllFilters={handleClearAllFilters}
            balancesRequesting={balancesRequesting}
            columns={columnsOld}
            balancesTotal={balancesTotal}
            balancesTotalPages={balancesTotalPages}
            balancesPageSize={balancesPageSize}
            balancesError={balancesError}
            emptyStateMsg={emptyStateMsg}
            emptyStateDescription={emptyStateDescription}
            emptyStateIconPath={emptyStateIconPath}
            currentPage={currentPage}
            handleResultsPerPageChange={handleResultsPerPageChange}
            handlePageChange={handlePageChange}
            handleSortChange={handleSortChange}
            handleOnSelectTab={handleOnSelectTab}
            onItemClick={onItemClick}
          />
        ) : (
          <NewExtractComponent
            balancesItems={balancesItems}
            filters={filters}
            isBanking={isBanking}
            tabIndex={tabIndex}
            tabs={tabs}
            search={search}
            handleSearchSubmit={handleSearchSubmit}
            handleFilterSubmit={handleFilterSubmit}
            handleClearAllFilters={handleClearAllFilters}
            balancesRequesting={balancesRequesting}
            columns={columns}
            balancesTotal={balancesTotal}
            balancesTotalPages={balancesTotalPages}
            balancesPageSize={balancesPageSize}
            balancesError={balancesError}
            emptyStateMsg={emptyStateMsg}
            emptyStateDescription={emptyStateDescription}
            emptyStateIconPath={emptyStateIconPath}
            currentPage={currentPage}
            handleResultsPerPageChange={handleResultsPerPageChange}
            handlePageChange={handlePageChange}
            handleSortChange={handleSortChange}
            handleOnSelectTab={handleOnSelectTab}
            onItemClick={onItemClick}
            amountType={amountType}
            handleChangeAmountType={handleChangeAmountType}
            handleIdTransaction={handleIdTransaction}
            clearIdTransaction={clearIdTransaction}
          />
        )}
      </>
    </>
  )
}

BalanceTable.defaultProps = {
  filters: {},
  columnsOld: [
    {
      Header: 'Data',
      accessor: 'date',
      Cell: ({ cell }) => (
        <Label overflow fontSize={normalText} color={darkGray}>
          {moment(cell.value).format('DD/MM/YYYY HH:mm:ss')}
        </Label>
      ),
      sortable: false
    },
    {
      Header: 'Tipo de operação',
      accessor: 'entry_type',
      Cell: ({ cell }) => (
        <Label color={darkGray} fontSize={normalText}>
          {getStatusLabel(cell.value).label}
        </Label>
      ),
      sortable: false
    },
    {
      Header: 'NSU da operação',
      accessor: 'nsu',
      Cell: ({ cell }) => (
        <Label color={darkGray} uppercase fontSize={normalText}>
          {cell.value}
        </Label>
      ),
      sortable: true
    },
    {
      Header: 'Código de autorização',
      Cell: ({ row }) => (
        <LabelCopyToClipboard
          color={darkerGray}
          text={
            row.original.transaction?.number || row.original.transfer?.number
          }
        >
          {row.original.transaction?.number || row.original.transfer?.number}
        </LabelCopyToClipboard>
      ),
      sortable: false
    },
    {
      Header: 'Método',
      accessor: 'entry_description',
      Cell: ({ cell }) => (
        <Label color={darkGray} fontSize={normalText} overflow>
          {cell.value}
        </Label>
      ),
      sortable: false
    },
    {
      Header: 'Valor Bruto',
      accessor: 'amount',
      Cell: ({ cell }) => (
        <Label color={darkGray} fontSize={normalText} overflow>
          {parseFloat(cell.value).toMoney()}
        </Label>
      ),
      sortable: false
    },
    {
      Header: 'Taxa',
      accessor: 'fee',
      Cell: ({ cell }) => (
        <Label color={darkGray} fontSize={normalText} overflow>
          {parseFloat(cell.value).toMoney()}
        </Label>
      ),
      sortable: false
    },
    {
      Header: 'Valor Liquido',
      accessor: 'net_amount',
      Cell: ({ cell }) => (
        <Label color={darkGray} fontSize={normalText} overflow>
          {parseFloat(cell.value).toMoney()}
        </Label>
      ),
      sortable: false
    },
    {
      Header: 'Saldo',
      accessor: 'current_balance',
      Cell: ({ cell }) => (
        <Label color={darkGray} fontSize={normalText} overflow>
          {parseFloat(cell.value).toMoney()}
        </Label>
      ),
      sortable: false
    }
  ],
  columns: [
    {
      Header: 'Tipo de operação',
      accessor: 'entry_type',
      Cell: ({ cell }) => {
        const { entry_type, metadata } = cell.row.original

        if (metadata?.nr_processo !== undefined) {
          if (entry_type === 'block') {
            return (
              <Label color={darkGray} fontSize={normalText}>
                {getStatusLabel('block_sisbajud').label}
              </Label>
            )
          }
          if (entry_type === 'block_refunded') {
            return (
              <Label color={darkGray} fontSize={normalText}>
                {getStatusLabel('block_refunded_sisbajud').label}
              </Label>
            )
          }
        }
        return (
          <Label color={darkGray} fontSize={normalText}>
            {getStatusLabel(cell.value).label}
          </Label>
        )
      },

      sortable: false
    },
    {
      Header: 'Movimentação',
      accessor: 'entry_description',
      Cell: ({ cell }) => (
        <Label color={darkGray} fontSize={normalText} overflow>
          {cell.value}
        </Label>
      ),
      sortable: false
    },
    {
      Header: 'Valor do lançamento',
      accessor: 'net_amount',
      Cell: ({ cell }) => (
        <Label color={darkGray} fontSize={normalText} overflow>
          {parseFloat(cell.value).toMoney()}
        </Label>
      ),
      sortable: false
    }
  ],
  onItemClick: () => {
    /* noop */
  }
}

const ChangeViewWrapper = styled.div`
  display: flex;
  gap: 10px 20px;
  align-items: center;
  margin-bottom: ${(props) => (props.isBanking ? '0px' : '-3.8rem')};
`

BalanceTable.propTypes = {
  filters: PropTypes.object,
  columns: PropTypes.any,
  columnsOld: PropTypes.any,
  onItemClick: PropTypes.func
}

export { BalanceTable as Table }
