import React from 'react'

import { Title as Helmet } from 'src/Helpers/WhiteLabel'

import MainTemplate, { Container, Column } from 'src/UIComponents/Template'
import Grid from 'src/UIComponents/Grid/Grid'
import SellerConfigurationComponent from 'src/Components/Seller/SellerConfiguration'

const SellerConfiguration = () => {
  return (
    <MainTemplate>
      <Helmet>Configuration</Helmet>

      <Container>
        <Column>
          <Grid noPadding>
            <SellerConfigurationComponent />
          </Grid>
        </Column>
      </Container>
    </MainTemplate>
  )
}

export default SellerConfiguration
