import MainTemplate, { Column, Container } from 'src/UIComponents/Template'

import FutureTransferHeaderInfo from '../../Components/FutureTransfer/FutureTransferHeaderInfo'
import Grid from 'src/UIComponents/Grid/Grid'
import { Title as Helmet } from 'src/Helpers/WhiteLabel'
import React from 'react'
import { Table } from './Table'
import Title from 'src/UIComponents/Title/Title'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

const FutureTransfers = () => {
  const history = useHistory()

  const handleItemClick = (item) => {
    history.push(`lancamentos-futuros/venda/${item.id}`)
  }

  return (
    <MainTemplate>
      <Helmet>Lançamentos Futuros</Helmet>
      <TitleContainer>
        <Title bar big data-test={`title-page-extrato`}>
          <strong>
          Lançamentos Futuros
          </strong>
        </Title>
      </TitleContainer>
      <FutureTransferHeaderInfo />
      <Container>
        <Column>
          <Grid noPadding>
            <Table onItemClick={handleItemClick} />
          </Grid>
        </Column>
      </Container>
    </MainTemplate>
  )
}

export default FutureTransfers

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7.5rem;
`
