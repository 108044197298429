import React, { useEffect, useState } from 'react'
import { Title } from 'src/Helpers/WhiteLabel'
import Cookies from 'universal-cookie'

import LoginForm from 'src/Components/Authentication/LoginForm'
import CookieNotice from 'src/Components/TermsOfUseComponent/CookieNotice'
import { CookiePolicy } from 'src/Components/TermsOfUseComponent/CookiePolicy'

const LoginContainer = () => {
  const cookies = new Cookies()
  const [showCookieNoticeBox, setShowCookieNoticeBox] = useState(false)
  const [showPoliticModal, setShowPoliticModal] = useState(false)

  const handleCookieNoticeBox = () => {
    setShowCookieNoticeBox(!showCookieNoticeBox)
  }

  const handleShowPoliticModal = () => {
    setShowPoliticModal(!showPoliticModal)
  }

  const closePoliticModal = () => {
    handleShowPoliticModal()
    handleCookieNoticeBox()
  }

  const handleAcceptCookie = () => {
    closePoliticModal()
    cookies.set('acceptCookies', true)
  }

  useEffect(() => {
    const showBox = cookies.get('acceptCookies')
    if (!showBox) {
      setTimeout(() => handleCookieNoticeBox(), 1000)
    }
  }, [])

  return (
    <>
      <Title>Login</Title>
      <LoginForm />
      {showCookieNoticeBox && (
        <CookieNotice
          onClick={handleShowPoliticModal}
          onClose={handleCookieNoticeBox}
        />
      )}
      {showPoliticModal && <CookiePolicy handleOnClose={handleAcceptCookie} />}
    </>
  )
}
export default LoginContainer
