import React, { Fragment, Component } from 'react'
import { Title } from 'src/Helpers/WhiteLabel'

import CreateUser from 'src/Components/Authentication/CreateUser'

export default class CreateUserContainer extends Component {
  render () {
    return (
      <Fragment>
        <Title>Criar Conta</Title>
        <CreateUser />
      </Fragment>
    )
  }
}
